<template>
    <HeroPage v-if="pageData" :data="pageData" />

    <ContentGridFilterCollection :paging="paging" searchParams="" :collectionFilter="collectionFilter"
        contentType="trainersAndCoaches" />
</template>

<script>
import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

import HeroPage from "@/components/HeroPage";

import ContentGridFilterCollection from "@/components/ContentGridFilterCollection";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    data() {
        return {
            pageData: null,
            componentContent: null,
            paging: {
                pageNumber: 1,
                pageSize: 50,
            },
            collectionFilter: ["Coaches & Trainers"],
        };
    },
    components: {
        HeroPage,
        ContentGridFilterCollection,
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "trainers-and-coaches").then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
